import React from 'react'
import Styled from 'styled-components'
import Wrapper from '../components/wrapper'
import SEO from '../components/seo'
import Body from '../components/body'
import Header from '../components/header'
import ContactSnippet from '../components/contactSnippet'
import Footer from '../components/footer'

const Spacer = Styled.div`
    @media screen and (min-width: 720px) {
        margin-bottom: 50px;    
    }
`
const Title = Styled.h3`
    text-align: center;
    margin: 5px;
    font-size: 24px;
    font-weight: normal;
`
const Paragraph = Styled.p`
    margin: 5px 5px 30px 5px;
`

const SexTherapyPage = () => (
    <Wrapper>
      <SEO title="Sex Therapy" />
      <Header />
      <Body>
        <Spacer />
        <Title>What is sex therapy?</Title>
        <Paragraph>In most states and provinces, Sex Therapy is not a separately licensed or regulated profession, just as child psychotherapy or geriatric psychotherapy is not government regulated beyond granting the basic license to practice therapy. To assure the public of highly qualified practitioners, AASECT provides voluntary certification to those therapists who have completed the rigorous certification process. Only those therapists who have met these qualifications may designate themselves as “AASECT Certified Sex Therapists.”</Paragraph>
        <Title>What kinds of problems can benefit from Sex Therapy?</Title>
        <Paragraph>Typically people experiencing concerns about arousal, performance, or satisfaction are likely to benefit from Sex Therapy. Among these problems are decreased or increased desire for intimacy, or in the case of a couple, mismatched or discrepant desire or interest in sexual intimacy. Both men and women can experience concerns about arousal and there are many causes and options for solving these problems. At any age, performance or lovemaking skills can be of concern, just as can issues around orgasm and satisfaction.</Paragraph>
        <Paragraph>Additionally concerns about sexual trauma in one’s background, medical conditions that affect one’s sexuality, sexual pain disorders, concerns about gender identity or sexual orientation, and issues around sexual compulsivity or addiction are frequent concerns that people discuss with a Certified Sex Therapist.</Paragraph>
        <Title>What happens in Sex Therapy?</Title>
        <Paragraph>The Sex Therapy process is very similar to that experienced with other mental health practitioners. The Certified Sex Therapist will meet with the person as an individual or with a couple in an office setting where an extensive history of the concerns will be taken. The Certified Sex Therapist will note both the psychological and the physical components and will establish one or more diagnoses. After this, a treatment plan will be proposed, usually with your involvement in its development. In some instances, the Certified Sex Therapist may work closely with the person’s physician, nurse, or other therapist or counselor to establish causes and remedies for the problems.</Paragraph>
        <Paragraph>Depending on the diagnosis, the Certified Sex Therapist will educate the person or couple about the issue and about options for change. This educational process may occur through suggested reading material, through watching educational audio-visual materials, through discussion with the therapist, through attending workshops, or all of these therapy processes. Sometimes having more information will allow the problem to resolve. Sometimes more specific or intensive therapy will be needed.</Paragraph>
        <Paragraph>If more specific therapy is needed, the Certified Sex Therapist may suggest a regular schedule of office appointments. Often, homework exercises to be practiced individually or as a couple in the privacy of one’s home between office appointments will be suggested. The homework may be as general as communication exercises or as specific as actual sexual experiences, depending on the progress in therapy and the person’s level of comfort with accepting direction.</Paragraph>
        <Paragraph>In no instances will a Certified Sex Therapist engage in any kind of sexual activity with a therapy patient/client, whether in the office or in any location. To do so is a breach of ethics, and in some states and provinces is a crime.</Paragraph>
        <Paragraph><b>Dr. Kimberly A. Ludwigsen is a Certified Sex Therapist through the American Association of Sexuality Educators, Counselors and Therapists Certified Sex Therapist (AASECT-CST) trained to provide in-depth psychotherapy specializing in treating clients with sexual issues and concerns.  Some therapists may state that they do sex therapy, but in fact have no specialized training at all.  To ensure you are getting the therapy you deserve, ask your therapist if they are a Certified Sex Therapist through AASECT. </b></Paragraph>
        <ContactSnippet />
      </Body>
      <Footer />
    </Wrapper>
)

export default SexTherapyPage
